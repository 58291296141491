// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Arial, sans-serif;
}
.container-fluid .jumbotron, .container .jumbotron {
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (min-width: 768px)
{
.container-fluid .jumbotron, .container .jumbotron {
    padding-left: 60px;
    padding-right: 60px;
}
}

@media screen and (min-width: 768px)
{
.jumbotron {
    padding-bottom: 48px;
    padding-top: 48px;
}
}

.jumbotron {
  background-color: #eee;
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  padding-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;AACA;;AAEA;;AAEA;IACI,oBAAoB;IACpB,iBAAiB;AACrB;AACA;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;EACpB,iBAAiB;AACnB","sourcesContent":["body {\n  font-family: Arial, sans-serif;\n}\n.container-fluid .jumbotron, .container .jumbotron {\n  border-radius: 6px;\n  padding-left: 15px;\n  padding-right: 15px;\n}\n\n@media screen and (min-width: 768px)\n{\n.container-fluid .jumbotron, .container .jumbotron {\n    padding-left: 60px;\n    padding-right: 60px;\n}\n}\n\n@media screen and (min-width: 768px)\n{\n.jumbotron {\n    padding-bottom: 48px;\n    padding-top: 48px;\n}\n}\n\n.jumbotron {\n  background-color: #eee;\n  padding-left: 60px;\n  padding-right: 60px;\n  margin-bottom: 30px;\n  padding-bottom: 30px;\n  padding-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
