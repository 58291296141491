import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function App() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(`Name: ${name}, Email: ${email}`);
  };

  return (
    <Container fluid>
      <Row justify="center">
        <Col xs={12} sm={8} md={6} lg={4}>
          <div class="jumbotron" className="text-center">
            <h1>Welcome to our landing page!</h1>
            <p>Learn more about our product or service here.</p>
            <Button variant="primary" href="#about" role="button">
              Learn More
            </Button>
          </div>
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={12} sm={8} md={6} lg={4}>
          <form onSubmit={handleSubmit}>
            <label>Enter your name:</label>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            <br />
            <label>Enter your email:</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <br />
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </form>
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={12} sm={8} md={6} lg={4}>
          <p id="about">About Us</p>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
